import { PageEvent } from '@angular/material/paginator';
import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { SearchForm } from 'src/app/shared/models/elastic.vm.model';
import { AccountUser } from '../../../../../../src/app/shared/models/account-user.model';
import { DriverAggregate, DriverEta, DriverState, DriverTelemetry } from '../../shared/models/driver.model';
import { DriverSearchResponse } from '../../shared/models/driver.vm.model';
import { SearchResponse } from '../../shared/models/elastic-api.model';
import { VehicleAggregate } from '../../shared/models/vehicle.model';

export const queryParamsChanged = createAction(
  '[Support agent driver list] Query params changed',
  props<{ queryParams: Params }>(),
);

export const searchSubmitButtonClicked = createAction(
  '[Support agent driver list] Search button clicked',
  props<{ searchForm: SearchForm }>(),
);

export const searchResponseFetched = createAction(
  '[Support agent driver list] Search response fetched',
  props<{ searchResponse: SearchResponse<DriverSearchResponse> }>(),
);

export const driverListFetched = createAction(
  '[Support agent driver list] List fetched',
  props<{ drivers: DriverAggregate[] }>(),
);

export const pageChanged = createAction('[Support agent driver list] Page changed', props<{ page: PageEvent }>());

export const driverEditButtonClicked = createAction(
  '[Support agent driver list] Driver edit init',
  props<{ driverId: string }>(),
);

export const driverUsersFetched = createAction(
  '[Support agent driver list] Driver users fetched',
  props<{ driverUsers: AccountUser[] }>(),
);

export const driverDetailsInit = createAction(
  '[Support agent driver details] Driver edit init',
  props<{ driverId: string }>(),
);

export const driverDetailsDestroyed = createAction('[Support agent driver details] Driver edit destroyed');

export const driverDetailsFetched = createAction(
  '[Support agent driver details] Driver details fetched',
  props<{ driverAggregate: DriverAggregate }>(),
);

export const driverStateFetched = createAction(
  '[Support agent driver details] Driver state fetched',
  props<{ state: DriverState }>(),
);

export const driverTelemetryFetched = createAction(
  '[Support agent driver details] Driver telemetry fetched',
  props<{ telemetry: DriverTelemetry }>(),
);

export const navigateToIntercomUserProfileButtonClicked = createAction(
  '[Support agent driver details] Navigate to Intercom user profile button clicked',
  props<{ userId: string }>(),
);

export const tripsFetched = createAction(
  '[Support agent driver details] Driver trip list fetched',
  props<{ trips: BookingAggregate[] }>(),
);

export const driverEtaFetched = createAction(
  '[Support agent driver details] Driver ETA fetched',
  props<{ eta: DriverEta }>(),
);

export const vehicleFetched = createAction(
  '[Support agent driver details] Driver vehicle fetched',
  props<{ vehicle: VehicleAggregate }>(),
);

export const driverUserFetched = createAction(
  '[Support agent driver details] Driver user fetched',
  props<{ driverUser: AccountUser }>(),
);
